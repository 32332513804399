@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:wght@300&display=swap');

body {
    background-color: #222;
    color: #ddd;
    font-family: "Roboto Serif", mono;
    font-size: 150%;
    padding: 30px;
    padding-bottom: 100px;
    text-align: center;
}

a {
    color: #ddd;
    text-decoration: none;
}
p a {color: #a88}
a:hover {color: #666;}

p {
    text-align: center;
    margin: auto;
    padding-bottom: 20px;
    max-width: 1200px;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    opacity: 0.6;
    max-width: 200px;
    border: 1px solid grey;
    border-radius: 10px;
}
img:hover {opacity: 1;}

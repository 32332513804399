h1 {
    text-align: center;
    font-size: 2em;
    position: relative;
    top: 10px;
}

#top, #bottom {
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    margin: 0;
    background: #050505;
}

#top {top: 0;}
#bottom {bottom: 0;}

#top li, #bottom li {
    float: left;
    display: block;
    text-align: center;
    font-size: 1em;
    padding: 10px;
}
#bottom li {float: right;}
